import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { AuthContext } from '../../context/auth-context'
import Card from '../common/Card'
import {isArray} from "@apollo/client/utilities";

const ConversionsChart = ({ data }) => {
    const { user } = useContext(AuthContext)

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const today = new Date()
    const last12Months = []

    for (let i = 11; i >= 0; i--) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1)
        last12Months.push(monthNames[date.getMonth()])
    }

    const conversions = isArray(data.conversionsByMonths) ? data.conversionsByMonths : JSON.parse(data.conversionsByMonths)

    const chart1 = {
        options: {
            chart: {
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                categories: last12Months,
            },
        },
        series: [
            {
                name: 'Conversions',
                data: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
                    ? [10, 41, 35, 51, 49, 62, 69, 91, 148, 156, 160, 178]
                    : (conversions ? new Array(12 - conversions.length).fill(0).concat(conversions) : new Array(12).fill(0)),
            }
        ],
    }

    const options = {
        theme: {
            monochrome: {
                enabled: true,
                color: '#427EEB',
                shadeTo: 'light'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 250
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        dataLabels: {
            style: {
                fontSize: '16px'
            }
        }
    }

    return (
        <Col lg="12">
            <Card>
                <Card.Header className="flex-wrap d-flex justify-content-between align-items-center">
                    <Card.Header.Title>
                        <h4 className="card-title">Conversions</h4>
                    </Card.Header.Title>
                </Card.Header>
                <Card.Body>
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="blog-chart-1"
                            role="tabpanel"
                        >
                            <Chart
                                options={chart1.options}
                                series={chart1.series}
                                type="line"
                                height="245"
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ConversionsChart