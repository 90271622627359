import Dashboard from '../pages/Dashboard'
import PageNotFound from '../pages/errors/PageNotFound'
import AppLayout from '../layouts/AppLayout'
import Campaign from '../pages/Campaign'
import SocialFeed from '../pages/SocialFeed'
import Reports from '../pages/Reports'
import Communities from '../pages/Communities'
import Leads from '../pages/Leads'
import { DocumentsRoutes } from './documents-routes'

export const MainRoutes =  [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: 'audiences',
                element: <SocialFeed />
            },
            {
                path: 'reports',
                element: <Reports />
            },
            {
                path: 'communities',
                element: <Communities />
            },
            {
                path: 'campaign',
                element: <Campaign />
            },
            {
                path: 'crm',
                element: <Leads />
            },
            ...DocumentsRoutes
        ]
    },
    {
        path: '*',
        element: <PageNotFound />
    }
]