import  React,{ memo, Fragment } from 'react'
import Card from '../common/Card'
import ProgressBar from '../common/ProgressBar'
import CountUp from 'react-countup'

const ConversionCard = memo((props) =>{
    const start = 0
    const duration = 3
    return (
        <Fragment>
            <Card>
                <Card.Body className="d-flex align-items-center">
                    <div className={`p-4 bg-soft-primary rounded-pill ${props.colour}`} style={{marginRight: '20px'}}>
                        {props.svg}
                    </div>

                    <div className="progress-detail w-100">
                        <p className="mb-3">{props.label}</p>
                        <h4 className="counter">
                            <CountUp start={start} end={props.number} duration={duration}/>
                            <div className="mt-1">
                                $
                                <CountUp start={start} end={props.value} duration={duration}/>
                            </div>
                        </h4>
                        <div className="w-100">
                            <ProgressBar
                                softcolors={props.progress}
                                color={props.progress}
                                className="shadow-none w-100"
                                value={props.rate}
                                minvalue={0}
                                maxvalue={100}
                                style={{height: "8px"}}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
})

export default ConversionCard