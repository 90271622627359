import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Dropdown, Button, Image, Collapse } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import debounce from 'lodash.debounce'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { InView } from 'react-intersection-observer'
import ScrollToTop from 'react-scroll-to-top'
import { ToastContainer } from 'react-toastify'
import Card from '../components/common/Card'
import CustomToggle from '../layouts/components/CustomToggle'
import Loader from '../layouts/components/Loader'
import { logos } from '../utils/platform-logos'
import FeedCard from '../components/feed/FeedCard'
import Masonry from '../components/common/Masonry'
import FilterOptions from '../components/feed/FilterOption'
import ResponsiveMasonry from '../components/common/ResponsiveMasonry'
import { capitaliseFirstLetter } from '../utils/tools'
import { CORE_FEED_FIELDS } from '../graphql/feed-fragments'
import { showNotification } from '../utils/notifications'

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions, $where: PlatformWhere) {
        platforms(options: $options, where: $where) {
            id
            name
            type
        }
    }
`

const GET_FEEDS = gql`
    ${CORE_FEED_FIELDS}
    query Feeds($where: FeedWhere, $options: FeedOptions) {
        feeds(where: $where, options: $options) {
            ...CoreFeedFields
            comments {
                ...CoreFeedFields
            }
            keywords {
                id
                value
            }
        }
    }
`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
            keywords
        }
    }
`

const SENTIMENT_OPTIONS = {
    POSITIVE: 'positive',
    NEUTRAL: 'neutral',
    NEGATIVE: 'negative',
}

const SocialFeed = () => {
    const [platform, setPlatform] = useState(null)
    const [heights, setHeights] = useState({})
    const [isFullyLoaded, setFullyLoaded] = useState(false)
    const [isOpenFilters, setIsOpenFilters] = useState(false)
    const [keywords, setKeywords] = useState([])
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [sentiments, setSentiments] = useState([])
    const [filters, setFilters] = useState([])
    const [dateRange, setDateRange] = useState({ from: null, to: null })
    const [deleting, setDeleting] = useState(null)

    const campaign = useQuery(GET_CAMPAIGN)

    useEffect(() => {
        if (!campaign.loading && campaign?.data?.campaigns) {
            if (campaign.data.campaigns[0].keywords !== null) {
                setKeywords(JSON.parse(campaign.data.campaigns[0].keywords))
            }
        }
    }, [campaign])

    const platforms = useQuery(GET_PLATFORMS, {
        variables: {
            options: { sort: [{ order: 'ASC' }] },
            where: { status: 'ACTIVE' },
        },
        onCompleted: () => {
            setPlatform(platforms.data.platforms[0])
            fetchFeeds({
                variables: {
                    where: {
                        platform: { id: platforms.data.platforms[0].id },
                        type: null,
                    },
                    options: { sort: [{ postedAt: 'DESC' }], limit: 18, offset: 0 },
                },
            })
        },
    })

    const [fetchFeeds, { data: feedsData, loading: feedsLoading, fetchMore }] = useLazyQuery(GET_FEEDS)

    const onChangePlatform = (platform) => {
        setPlatform(platform)
        applyFilters(platform, dateRange, sentiments, selectedKeywords)
    }

    const onLoadMore = async () => {
        const result = await fetchMore({
            variables: {
                options: {
                    sort: [{ postedAt: 'DESC' }],
                    limit: 18,
                    offset: feedsData?.feeds.length,
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                const previousFeeds = prev?.feeds ?? []
                const newFeeds = fetchMoreResult.feeds ?? []

                return {
                    ...prev,
                    feeds: [...previousFeeds, ...newFeeds]
                }
            }
        })
        setFullyLoaded(!result.data.feeds.length)
    }

    const onDeleteFeed = async (id) => {
        setDeleting(id)
        const result = await fetchMore({
            variables: {
                options: {
                    sort: [{ postedAt: 'DESC' }],
                    limit: 0,
                    offset: feedsData?.feeds.length,
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                const filteredFeeds = prev.feeds.filter((feed) => feed.id !== id)
                return {
                    ...prev,
                    feeds: filteredFeeds
                }
            }
        })
        setTimeout(() => showNotification('The Feed has been deleted', 'success'), 10)
        setDeleting(null)
    }

    const handleHeightChange = (index, height) => {
        setHeights((prevState) => {
            return {
                ...prevState,
                [index]: height,
            }
        })
    }

    const debouncedApplyFilters = debounce(async (platform, dateRange, sentiments, selectedKeywords) => {
        const where = {
            platform: { id: platform?.id },
            type: null,
        }

        if (dateRange.from) {
            where.postedAt_GTE = dateRange.from.toISOString()
        }

        if (dateRange.to) {
            where.postedAt_LTE = dateRange.to.toISOString()
        }

        if (sentiments.length > 0) {
            where.sentiment_IN = sentiments
        }

        if (selectedKeywords.length > 0) {
            where.keywords_SOME = { value_IN: selectedKeywords }
        }

        setFullyLoaded(false)
        const result = await fetchFeeds({
            variables: {
                where,
                options: { sort: [{ postedAt: 'DESC' }], limit: 18, offset: 0 },
            },
        })
        setFullyLoaded(!result.data.feeds.length)
    }, 300)

    const applyFilters = (platform, dateRange, sentiments, selectedKeywords) => {
        debouncedApplyFilters(platform, dateRange, sentiments, selectedKeywords)
    }

    const handleFilterChange = (filter, checked, filterType) => {
        setFilters((prevFilters) => {
            let updatedFilters
            if (checked) {
                updatedFilters = [...prevFilters, filter]
            } else {
                updatedFilters = prevFilters.filter((f) => f !== filter)
            }

            if (filterType === 'keyword') {
                setSelectedKeywords((prevKeywords) => {
                    const newKeywords = checked ? [...prevKeywords, filter] : prevKeywords.filter((k) => k !== filter)
                    applyFilters(platform, dateRange, sentiments, newKeywords)
                    return newKeywords
                })
            } else if (filterType === 'sentiment') {
                setSentiments((prevSentiments) => {
                    const sentimentValue =
                        filter === SENTIMENT_OPTIONS.POSITIVE ? 1 : filter === SENTIMENT_OPTIONS.NEUTRAL ? 0 : -1
                    const newSentiments = checked
                        ? [...prevSentiments, sentimentValue]
                        : prevSentiments.filter((s) => s !== sentimentValue)
                    applyFilters(platform, dateRange, newSentiments, selectedKeywords)
                    return newSentiments
                })
            }

            // Update date range state if date filter is removed
            if (filter.startsWith('From:')) {
                setDateRange((prevDateRange) => {
                    const newDateRange = { ...prevDateRange, from: null }
                    applyFilters(platform, newDateRange, sentiments, selectedKeywords)
                    return newDateRange
                })
            } else if (filter.startsWith('To:')) {
                setDateRange((prevDateRange) => {
                    const newDateRange = { ...prevDateRange, to: null }
                    applyFilters(platform, newDateRange, sentiments, selectedKeywords)
                    return newDateRange
                })
            }

            return updatedFilters
        })
    }

    const handleDateChange = (rangeType, date) => {
        setDateRange((prevDateRange) => {
            const newDateRange = { ...prevDateRange, [rangeType]: date[0] || null }
            const fromFilter = newDateRange.from ? `From: ${newDateRange.from.toLocaleDateString()}` : null
            const toFilter = newDateRange.to ? `To: ${newDateRange.to.toLocaleDateString()}` : null

            setFilters((prevFilters) => {
                let updatedFilters = prevFilters.filter(
                    (filter) => !filter.startsWith('From: ') && !filter.startsWith('To: ')
                )

                if (fromFilter) {
                    updatedFilters.push(fromFilter)
                }

                if (toFilter) {
                    updatedFilters.push(toFilter)
                }

                return updatedFilters
            })

            applyFilters(platform, newDateRange, sentiments, selectedKeywords)

            return newDateRange
        })
    }

    const clearDateFilter = (rangeType) => {
        handleDateChange(rangeType, [null])
    }

    const clearAllFilters = async () => {
        setFullyLoaded(false)
        setFilters([])
        setDateRange({ from: null, to: null })
        setSelectedKeywords([])
        setSentiments([])
        const result = await fetchFeeds({
            variables: {
                where: { platform: { id: platform?.id }, type: null },
                options: { sort: [{ postedAt: 'DESC' }], limit: 18, offset: 0 }
            }
        })
        setFullyLoaded(!result.data.feeds.length)
    }

    return (
        <Fragment>
            {platforms.loading ? (
                <Loader />
            ) : (
                <Row style={{marginBottom: '20px'}}>
                    <Col lg="12">
                        <Card>
                            <Card.Header className="align-items-center flex-wrap">
                                <h4 className="mb-0">
                                    <i className="icon">
                                        <svg
                                            className="icon-25"
                                            width="25"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.4"
                                                d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M11.9805 13.2901C11.2705 13.2801 10.7005 12.7101 10.7005 12.0001C10.7005 11.3001 11.2805 10.7201 11.9805 10.7301C12.6905 10.7301 13.2605 11.3001 13.2605 12.0101C13.2605 12.7101 12.6905 13.2901 11.9805 13.2901ZM7.3701 13.2901C6.6701 13.2901 6.0901 12.7101 6.0901 12.0101C6.0901 11.3001 6.6601 10.7301 7.3701 10.7301C8.0801 10.7301 8.6501 11.3001 8.6501 12.0101C8.6501 12.7101 8.0801 13.2801 7.3701 13.2901ZM15.3103 12.0101C15.3103 12.7101 15.8803 13.2901 16.5903 13.2901C17.3003 13.2901 17.8703 12.7101 17.8703 12.0101C17.8703 11.3001 17.3003 10.7301 16.5903 10.7301C15.8803 10.7301 15.3103 11.3001 15.3103 12.0101Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </i>{' '}
                                    {capitaliseFirstLetter(platform?.type)} Feed <span
                                    className="hide-on-mobile">- {platform?.name}{' '}</span>
                                    <Button variant="sm bg-white rounded-circle p-2 no-pointer">
                                        <Image
                                            src={logos[platform?.name]}
                                            alt={platform?.name}
                                            className="img-fluid object-contain"
                                            style={{width: '20px', height: '20px'}}
                                        />
                                    </Button>
                                </h4>
                                <div className="d-flex flex-row">
                                    <div className={`text-secondary dropdown-toggle ${isOpenFilters ? 'show' : ''}`}
                                         style={{marginRight: '20px', cursor: 'pointer'}}
                                         onClick={() => setIsOpenFilters(!isOpenFilters)}
                                    >
                                        Filters{' '}
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} variant="text-secondary dropdown-toggle">
                                            Select Platform
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu aria-labelledby="dropdownMenuButtonPlatform">
                                            {platforms.data.platforms.map((item) => {
                                                return (
                                                    <li key={item.id}>
                                                        <Dropdown.Item href="#" onClick={() => onChangePlatform(item)}>
                                                            <Button
                                                                variant="sm bg-white rounded-circle no-pointer"
                                                                style={{width: '22px', padding: 0}}
                                                            >
                                                                <Image
                                                                    src={logos[item.name]}
                                                                    alt={item.name}
                                                                    className="img-fluid object-contain"
                                                                    style={{width: '10px', height: '10px'}}
                                                                />
                                                            </Button>{' '}
                                                            {item.name}
                                                        </Dropdown.Item>
                                                    </li>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Collapse in={isOpenFilters}>
                                    <Row>
                                        <Col md={4} className="mb-5">
                                            <h5 className="mb-3">Keywords</h5>
                                            <div>
                                                {keywords.map((keyword, index) => (
                                                    <FilterOptions
                                                        key={index}
                                                        uniqueName="keyword"
                                                        id={index.toString()}
                                                        productName={keyword}
                                                        productChecked={selectedKeywords.includes(keyword)}
                                                        onChange={(e) =>
                                                            handleFilterChange(keyword, e.target.checked, 'keyword')
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </Col>

                                        <Col md={4} className="mb-5">
                                            <h5 className="mb-3">Posted Date</h5>
                                            <div>
                                                <div className="d-flex">
                                                    <Flatpickr
                                                        options={{maxDate: 'today', dateFormat: 'd/m/Y'}}
                                                        className="form-control range_flatpicker"
                                                        value={dateRange.from}
                                                        onChange={(date) => handleDateChange('from', date)}
                                                        placeholder="Select From Date"
                                                    />
                                                    <Button
                                                        className="btn btn-sm btn-outline-secondary ml-2"
                                                        onClick={() => clearDateFilter('from')}
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <Flatpickr
                                                        options={{maxDate: 'today', dateFormat: 'd/m/Y'}}
                                                        className="form-control range_flatpicker"
                                                        value={dateRange.to}
                                                        onChange={(date) => handleDateChange('to', date)}
                                                        placeholder="Select To Date"
                                                    />
                                                    <Button
                                                        className="btn btn-sm btn-outline-secondary ml-2"
                                                        onClick={() => clearDateFilter('to')}
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={4}>
                                            <h5 className="mb-3">Sentiment</h5>
                                            <div>
                                                <div id="iq-product-filter-03" className="filter-collapse">
                                                    <FilterOptions
                                                        uniqueName="sentiment"
                                                        id="positive"
                                                        productName={SENTIMENT_OPTIONS.POSITIVE}
                                                        productChecked={filters.includes(SENTIMENT_OPTIONS.POSITIVE)}
                                                        onChange={(e) =>
                                                            handleFilterChange(
                                                                SENTIMENT_OPTIONS.POSITIVE,
                                                                e.target.checked,
                                                                'sentiment'
                                                            )
                                                        }
                                                    />
                                                    <FilterOptions
                                                        uniqueName="sentiment"
                                                        id="neutral"
                                                        productName={SENTIMENT_OPTIONS.NEUTRAL}
                                                        productChecked={filters.includes(SENTIMENT_OPTIONS.NEUTRAL)}
                                                        onChange={(e) =>
                                                            handleFilterChange(
                                                                SENTIMENT_OPTIONS.NEUTRAL,
                                                                e.target.checked,
                                                                'sentiment'
                                                            )
                                                        }
                                                    />
                                                    <FilterOptions
                                                        uniqueName="sentiment"
                                                        id="negative"
                                                        productName={SENTIMENT_OPTIONS.NEGATIVE}
                                                        productChecked={filters.includes(SENTIMENT_OPTIONS.NEGATIVE)}
                                                        onChange={(e) =>
                                                            handleFilterChange(
                                                                SENTIMENT_OPTIONS.NEGATIVE,
                                                                e.target.checked,
                                                                'sentiment'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <div className="align-items-center w-100">
                                    {filters.map((filter, index) => (
                                        <div key={index} className="bg-soft-primary rounded-pill iq-custom-badge">
                                            {filter}
                                            <Button
                                                className="btn btn-primary btn-sm rounded-pill iq-cancel-btn"
                                                onClick={() =>
                                                    handleFilterChange(
                                                        filter,
                                                        false,
                                                        filter.includes(SENTIMENT_OPTIONS.POSITIVE) ||
                                                            filter.includes(SENTIMENT_OPTIONS.NEUTRAL) ||
                                                            filter.includes(SENTIMENT_OPTIONS.NEGATIVE)
                                                            ? 'sentiment'
                                                            : 'keyword'
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    ></path>
                                                </svg>
                                            </Button>
                                        </div>
                                    ))}
                                    {filters.length > 1 && (
                                        <Button className="ml-auto btn btn-primary btn-sm" onClick={clearAllFilters}>
                                            Clear All
                                        </Button>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                        <Row>
                            {!feedsLoading && (
                                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 3 }}>
                                    <Masonry gutter={'32px'} heights={heights}>
                                        {feedsData?.feeds.map((feed, index) => {
                                            const uniqueKey = `${feed.id}-${index}`
                                            return (
                                                <FeedCard
                                                    key={uniqueKey}
                                                    data={feed}
                                                    platform={platform}
                                                    index={uniqueKey}
                                                    onHeightChange={handleHeightChange}
                                                    onDelete={onDeleteFeed}
                                                    onDeleting={deleting}
                                                />
                                            )
                                        })}
                                        <InView
                                            onChange={(inView) => {
                                                if (inView) {
                                                    onLoadMore()
                                                }
                                            }}
                                        />
                                    </Masonry>
                                </ResponsiveMasonry>
                            )}
                            {!isFullyLoaded && <Loader fullScreen={false} />}
                        </Row>
                    </Col>
                </Row>
            )}
            <ScrollToTop smooth color="white" className="turbamedia-fab" />
            <ToastContainer />
        </Fragment>
    )
}

export default SocialFeed
